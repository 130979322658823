.dash-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
}
.card-single {
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 25px 3px rgba(0,0,0,.025);
    border: 1px solid #eaeaea;
}

.card-body {
    padding: 1.3rem 1rem;
    display: flex;
    align-items: center;
    transition: all .2s ease-in-out;
}

.card-body:hover {
    background: #005386;
    span, h5, h4 {
        color: white;
    }
}

.card-body span {
    font-size: 1.7rem;
    color: #777;
    padding-right: 1rem;
}

.card-body h5 {
    color: grey;
    font-size: 1rem;
    margin-bottom: .2rem;
}

.card-body h4 {
    color: black;
    font-size: 1.1rem;
    margin-top: .2rem;
}

.card-footer {
    padding: .5rem 1rem;
    background: #f9fafc;
}

.card-footer a {
    font-size: 1.1rem;
    color: #5e5ed9;
}