.ui.menu .active.item {
    color: #005386 !important;
    font-weight: bold !important;
}

#biz-tap .ui.secondary.menu {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 0.375rem;
}

.ui.horizontal.divider:after, .ui.horizontal.divider:before {
    top: 0;
}