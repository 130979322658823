
html {
    --border-color :#d4d4d5;
    --main-color :#005386;
    --light-color : rgb(0, 164, 229);

    word-break: keep-all;

}

.no-drag {-ms-user-select: none; -moz-user-select: -moz-none; -webkit-user-select: none; -khtml-user-select: none; user-select:none;}
.no-border {
    border: none;
}
.no-border:focus {
    outline: none !important;
}
/*margin*/

.mg-0 {
    margin: 0 !important;
}

.mg-1 {
    margin: 1rem !important;
}
.mg-2 {
    margin: 1.5rem !important;
}
.mg-3 {
    margin: 2rem !important;
}
.mg-4 {
    margin: 2.5rem !important;
}
.mg-5 {
    margin: 3rem !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}
.mt-2 {
    margin-top: 0.375rem !important;
}
.mt-3 {
    margin-top: 0.5rem !important;
}
.mt-4 {
    margin-top: 0.75rem !important;
}
.mt-5 {
    margin-top: 1rem !important;
}
.mt-6 {
    margin-top: 1.5rem !important;
}
.mt-7 {
    margin-top: 2rem !important;
}
.mt-8 {
    margin-top: 3rem !important;
}
.mt-9 {
    margin-top: 4.5rem !important;
}
.mt-10 {
    margin-top: 6rem !important;
}


.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}
.mr-2 {
    margin-right: 0.375rem !important;
}
.mr-3 {
    margin-right: 0.5rem !important;
}
.mr-4 {
    margin-right: 0.75rem !important;
}
.mr-5 {
    margin-right: 1rem !important;
}
.mr-6 {
    margin-right: 1.5rem !important;
}
.mr-7 {
    margin-right: 2rem !important;
}
.mr-8 {
    margin-right: 3rem !important;
}
.mr-9 {
    margin-right: 4.5rem !important;
}
.mr-10 {
    margin-right: 6rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.mb-2 {
    margin-bottom: 0.375rem !important;
}
.mb-3 {
    margin-bottom: 0.5rem !important;
}
.mb-4 {
    margin-bottom: 0.75rem !important;
}
.mb-5 {
    margin-bottom: 1rem !important;
}
.mb-6 {
    margin-bottom: 1.5rem !important;
}
.mb-7 {
    margin-bottom: 2rem !important;
}
.mb-8 {
    margin-bottom: 3rem !important;
}
.mb-9 {
    margin-bottom: 4.5rem !important;
}
.mb-10 {
    margin-bottom: 6rem !important;
}

.ml-0 {
    margin-left: 0 !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}
.ml-2 {
    margin-left: 0.375rem !important;
}
.ml-3 {
    margin-left: 0.5rem !important;
}
.ml-4 {
    margin-left: 0.75rem !important;
}
.ml-5 {
    margin-left: 1rem !important;
}
.ml-6 {
    margin-left: 1.5rem !important;
}
.ml-7 {
    margin-left: 2rem !important;
}
.ml-8 {
    margin-left: 3rem !important;
}
.ml-9 {
    margin-left: 4.5rem !important;
}
.ml-10 {
    margin-left: 6rem !important;
}



.mh-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}
.mh-2 {
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
}
.mh-3 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}
.mh-4 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}
.mh-5 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.mh-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}
.mh-7 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.mh-8 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}
.mh-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
}
.mh-10 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
}



.mv-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.mv-2 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
}
.mv-3 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.mv-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
}
.mv-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.mv-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.mv-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.mv-8 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.mv-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
}
.mv-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}



/* padding */

.pd-0 {
    padding: 0rem !important;
}
.pd-1 {
    padding: 1rem !important;
}
.pd-2 {
    padding: 1.5rem !important;
}
.pd-3 {
    padding: 2rem !important;
}
.pd-4 {
    padding: 2.5rem !important;
}
.pd-5 {
    padding: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}
.pt-1 {
    padding-top: 0.25rem !important;
}
.pt-2 {
    padding-top: 0.375rem !important;
}
.pt-3 {
    padding-top: 0.5rem !important;
}
.pt-4 {
    padding-top: 0.75rem !important;
}
.pt-5 {
    padding-top: 1rem !important;
}
.pt-6 {
    padding-top: 1.5rem !important;
}
.pt-7 {
    padding-top: 2rem !important;
}
.pt-8 {
    padding-top: 3rem !important;
}
.pt-9 {
    padding-top: 4.5rem !important;
}
.pt-10 {
    padding-top: 6rem !important;
}


.pr-0 {
    padding-right: 0 !important;
}
.pr-1 {
    padding-right: 0.25rem !important;
}
.pr-2 {
    padding-right: 0.375rem !important;
}
.pr-3 {
    padding-right: 0.5rem !important;
}
.pr-4 {
    padding-right: 0.75rem !important;
}
.pr-5 {
    padding-right: 1rem !important;
}
.pr-6 {
    padding-right: 1.5rem !important;
}
.pr-7 {
    padding-right: 2rem !important;
}
.pr-8 {
    padding-right: 3rem !important;
}
.pr-9 {
    padding-right: 4.5rem !important;
}
.pr-10 {
    padding-right: 6rem !important;
}


.pb-0 {
    padding-bottom: 0 !important;
}
.pb-1 {
    padding-bottom: 0.25rem !important;
}
.pb-2 {
    padding-bottom: 0.375rem !important;
}
.pb-3 {
    padding-bottom: 0.5rem !important;
}
.pb-4 {
    padding-bottom: 0.75rem !important;
}
.pb-5 {
    padding-bottom: 1rem !important;
}
.pb-6 {
    padding-bottom: 1.5rem !important;
}
.pb-7 {
    padding-bottom: 2rem !important;
}
.pb-8 {
    padding-bottom: 3rem !important;
}
.pb-9 {
    padding-bottom: 4.5rem !important;
}
.pb-10 {
    padding-bottom: 6rem !important;
}


.pl-0 {
    padding-left: 0 !important;
}
.pl-1 {
    padding-left: 0.25rem !important;
}
.pl-2 {
    padding-left: 0.375rem !important;
}
.pl-3 {
    padding-left: 0.5rem !important;
}
.pl-4 {
    padding-left: 0.75rem !important;
}
.pl-5 {
    padding-left: 1rem !important;
}
.pl-6 {
    padding-left: 1.5rem !important;
}
.pl-7 {
    padding-left: 2rem !important;
}
.pl-8 {
    padding-left: 3rem !important;
}
.pl-9 {
    padding-left: 4.5rem !important;
}
.pl-10 {
    padding-left: 6rem !important;
}


.ph-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
.ph-2 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
}
.ph-3 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.ph-4 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}
.ph-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.ph-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.ph-7 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.ph-8 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.ph-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}
.ph-10 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}


.pv-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.pv-2 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
}
.pv-3 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.pv-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
.pv-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.pv-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.pv-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.pv-8 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.pv-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
}
.pv-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

/*font*/


.fs-m3 {
    font-size: 0.7em !important;
}
.fs-m2 {
    font-size: 0.8em !important;
}
.fs-m1 {
    font-size: 0.9em !important;
}
.fs-1 {
    font-size: 1.25em !important;
}
.fs-2 {
    font-size: 1.5em !important;
}
.fs-3 {
    font-size: 2em !important;
}
.fs-4 {
    font-size: 2.5em !important;
}
.fs-5 {
    font-size: 3em !important;
}
.fs-6 {
    font-size: 4em !important;
}
.fs-7 {
    font-size: 5em !important;
}

.fw-l {
    font-weight: 300 !important;
}

.fw-n {
    font-weight: 500 !important;
}

.fw-b {
    font-weight: 700 !important;
}


/* align & flexbox*/

.is-flex {
    display: flex;
}

.is-col {
    display: flex;
    flex-direction: column;
}
.is-row {
    display: flex;
    flex-direction: row;
}


.p-item-centered {
    display: flex !important;
    justify-content: center;
}
.p-item-vcentered {
    display: flex !important;
    align-items: center;
}

.has-item-centered {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.has-text-centered {
    text-align: center;
}
.has-text-right {
    text-align: right;
}

.has-text-left {
    text-align: left;
}

.is-centered {
    margin-left: auto;
    margin-right: auto;
}

.is-vcentered {
    vertical-align: center;
    align-items: center;
}

.is-top {
    align-self: flex-start;
}
.is-bottom {
    align-self: flex-end;
}


.float-r {
    float: right;
}

.float-l {
    float: left;
}
.is-top {
    align-self: flex-start;
}
.is-bottom {
    align-self: flex-end;
}
/*border & shadow*/

.border-a {
    border: 1px solid var(--border-color);
}

.border-t {
    border-top: 1px solid var(--border-color);
}
.border-r {
    border-right: 1px solid var(--border-color);
}
.border-b {
    border-bottom: 1px solid var(--border-color);
}
.border-l {
    border-left: 1px solid var(--border-color);
}

.p-round {
    border-radius: 0.5rem !important;
}
.p-round-little {
    border-radius: 0.25rem !important;
}
.p-round-big {
    border-radius: 1rem !important;
}

.shadow {
    box-shadow: 0 0 3px 3px rgba(60,60,60, 0.2);
}

/* img */
.p-img-w {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%
}
.p-img-h {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%
}

/*proportion*/


.p-box-1by1 {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
}
.p-box-2by1 {
    position: relative;
    width: 100%;
    padding-top: 50%;
    overflow: hidden;
}
.p-box-4by1 {
    position: relative;
    width: 100%;
    padding-top: 25%;
    overflow: hidden;
}
.p-box-6by1 {
    position: relative;
    width: 100%;
    padding-top: 16.67%;
    overflow: hidden;
}
.p-box-8by1 {
    position: relative;
    width: 100%;
    padding-top: 12.5%;
    overflow: hidden;
}
.p-box-10by1 {
    position: relative;
    width: 100%;
    padding-top: 10%;
    overflow: hidden;
}
.p-box-16by9 {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
}

.p-box-ch{
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

/*color*/

.bg-white {
    background-color: white;
}
.bg-gray {
    background-color: grey;
}
.bg-lgray {
    background-color: gainsboro;
}
.bg-main {
    background-color: var(--main-color) !important;
}
.bg-lmain {
    background-color:var(--light-color) !important;
}

.color-white {
    color: ghostwhite;
}
.color-gray {
    color:grey;
}
.color-black {
    color:black;
}
.color-black:hover {
    color:black;
}
.color-main {
    color:var(--main-color) !important;
}
.color-lmain {
    color:var(--light-color) !important;
}
.color-red {
    color:#F44336;
}
.color-green {
    color: #009688;
}
.color-blue {
    color:#1890ff;
}


/*Text*/
.text-ellipsis4L {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.text-ellipsis3L {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.text-ellipsis2L {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

/* size */

.full-h {
    height: 100%;
}
.full-w {
    width: 100%;
}

.full-wh {
    height: 100%;
    width: 100%;
}

.half-h {
    height: 50%;
}
.half-w {
    width: 50%;
}

.half-wh {
    height: 50%;
    width: 50%;
}

.quarter-h {
    height: 25%;
}
.quarter-w {
    width: 25%;
}

.quarter-wh {
    height: 25%;
    width: 25%;
}

/* element */

/*.p-card {*/
/*    background-color: white;*/
/*    border-radius: 0.5rem;*/
/*    border: 1px solid gainsboro;*/
/*    overflow: hidden;*/
/*}*/

.p-card {
    background-color: white;
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: 0 0 25px 3px rgba(0,0,0,.025);
    border: 1px solid #eaeaea;
    isolation: isolate;
}

.p-shadow {
    box-shadow : 1px 1px 2px 1px gainsboro;
}

.p-shadow-b {
    box-shadow : 2px 2px 2px 2px gainsboro;
}

/* hover */
.hover-gray {
    transition:  all .3s ease-in-out;
}
.hover-gray:hover {
    background-color: #eeeeee !important;
}


.hover-main {
    transition:  all .3s ease-in-out;
}
.hover-main:hover {
    background-color: var(--main-color) !important;
}


.hover-zoom {
    transition: all .2s ease-in-out;

}
.hover-zoom:hover {
    transform: scale(1.01);
}

.hover-up {
    transition:  all .2s ease-in-out;

}
.hover-up:hover {
    box-shadow : 1px 1px 3px 2px gainsboro;
    margin-top: -3px;
}

.pointer {
    cursor: pointer;
}

.p-button {
    padding: 1rem 1.5rem;
    border-radius: 20px;
    background: #005386;
    color: white;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.p-button:hover {
    background: #004772;
}

.p-subscribe-btn {
    padding: 1rem 1.5rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.p-gitple-btn {
    padding: 1.5rem 1.2rem;
    width: 60px;
    height: 60px;
    font-size: 25px;
    border-radius: 30px;
    background: #1bb177;
    color: white;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.p-gitple-btn:hover {
    background: #189f6b;
}