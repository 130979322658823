.posco-blue {
  background-color: #005386 !important;
  color: white;
}

.nav-logo:hover {
  background: transparent !important;
}

.auto-grid {
  --auto-grid-min-size: 350px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1.5rem;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .auto-grid {
    --auto-grid-min-size: 100%;
    padding: 0 1em;
  }
}

.ui.modal {
  border-radius: 20px !important;
}

.ui.modal>.icon:first-child+*, .ui.modal>:first-child:not(.icon) {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.ui.modal>:last-child {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.ui.dropdown .menu>.item>.image, .ui.dropdown .menu>.item>img, .ui.dropdown>.text>.image, .ui.dropdown>.text>img {
  margin-left: 0.2em;
  border: 1px solid #eaeaea;
}

.ui.search.dropdown, .ui.selection.dropdown {
  border-radius: 10px !important;
}

.ui.selection.active.dropdown .menu {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ui.upward.selection.dropdown .menu {
  border-radius: 10px 10px 0 0;
}

.ui.upward.selection.dropdown.visible {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ui.active.upward.selection.dropdown {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ui.menu {
  border-radius: 10px !important;
}

.ui.horizontal.divider:after, .ui.horizontal.divider:before {
  top: 0 !important;
}

input {
  border-radius: 10px !important;
}

textarea {
  border-radius: 10px !important;
}

.ui.button {
  border-radius: 15px !important;
  padding: 1rem 1.5rem !important;
}

table {
  border-radius: 15px !important;
  overflow: hidden !important;
}

.toastui-editor-defaultUI {
  border-radius: 10px !important;
  overflow: hidden;
}

.ProseMirror {
  height: 100% !important;
}
